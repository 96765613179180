import React, { useState, useEffect } from 'react';
import { FaUserFriends, FaShoppingCart, FaUikit, FaBuilding } from 'react-icons/fa';
import { motion } from 'framer-motion';

import Section from '../HOC/Section';
import bgImage from '../../assets/img/blurred-image-1.jpg';

const Facts = () => {
  const [startNumber1, setStartNumber1] = useState(0);
  const [startNumber2, setStartNumber2] = useState(0);
  const [startNumber3, setStartNumber3] = useState(0);
  const [startNumber4, setStartNumber4] = useState(0);

  const finalNumber1 = 26;
  const finalNumber2 = 4;
  const finalNumber3 = 2;
  const finalNumber4 = 140000;

  const counterVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: { opacity: 1, scale: 1 }
  };

  useEffect(() => {
    const updateCounter1 = () => {
      setStartNumber1((prevNumber) => prevNumber + 1);
    };

    if (startNumber1 < finalNumber1) {
      const timer = setTimeout(updateCounter1, 100); // Change the duration as needed
      return () => clearTimeout(timer);
    }
  }, [startNumber1, finalNumber1]);

  useEffect(() => {
    const updateCounter2 = () => {
      setStartNumber2((prevNumber) => prevNumber + 1);
    };

    if (startNumber2 < finalNumber2) {
      const timer = setTimeout(updateCounter2, 1000); // Change the duration as needed
      return () => clearTimeout(timer);
    }
  }, [startNumber2, finalNumber2]);

  useEffect(() => {
    const updateCounter3 = () => {
      setStartNumber3((prevNumber) => prevNumber + 1);
    };

    if (startNumber3 < finalNumber3) {
      const timer = setTimeout(updateCounter3, 2000); // Change the duration as needed
      return () => clearTimeout(timer);
    }
  }, [startNumber3, finalNumber3]);

  useEffect(() => {
    const updateCounter4 = () => {
      setStartNumber4((prevNumber) => prevNumber + 1);
    };

    if (startNumber4 < finalNumber4) {
      const timer = setTimeout(updateCounter4, -1); // Change the duration as needed
      return () => clearTimeout(timer);
    }
  }, [startNumber4, finalNumber4]);

  return (
    <Section id='facts'>
      <div className='facts-container' style={{ backgroundImage: `url(${bgImage})` }}>
        <div className='container pt-5 pb-4'>
          <div className='row'>
            <div className='col-sm-6 col-md-3 text-center mb-4'>
              <div className='mb-2 facts-icon text-danger'>
                <FaUserFriends />
              </div>
              <motion.h4
                className='facts-counter text-danger'
                variants={counterVariants}
                initial='hidden'
                animate='visible'
                transition={{ duration: 2.5 }}
              >
                {startNumber1}
              </motion.h4>
              <h5 className='facts-title text-danger'>Salariés</h5>
            </div>
            <div className='col-sm-6 col-md-3 text-center mb-4'>
              <div className='mb-2 facts-icon text-danger'>
                <FaShoppingCart />
              </div>
              <motion.h4
                className='facts-counter text-danger'
                variants={counterVariants}
                initial='hidden'
                animate='visible'
                transition={{ duration: 2.5 }}
              >
                {startNumber2}
              </motion.h4>
              <h5 className='facts-title text-danger'>Agences</h5>
            </div>
            <div className='col-sm-6 col-md-3 text-center mb-4'>
              <div className='mb-2 facts-icon text-danger'>
                <FaBuilding />
              </div>
              <motion.h4
                className='facts-counter text-danger'
                variants={counterVariants}
                initial='hidden'
                animate='visible'
                transition={{ duration: 2.5 }}
              >
                {startNumber3}
              </motion.h4>
              <h5 className='facts-title text-danger'>Centrales d'achat</h5>
            </div>
            <div className='col-sm-6 col-md-3 text-center mb-4'>
              <div className='mb-2 facts-icon text-danger'>
                <FaUikit />
              </div>
              <motion.h4
                className='facts-counter text-danger'
                variants={counterVariants}
                initial='hidden'
                animate='visible'
                transition={{ duration: 2.5 }}
              >
                {startNumber4}
              </motion.h4>
              <h5 className='facts-title text-danger'>Références</h5>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Facts;
