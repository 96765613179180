import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './styles/_styles.scss';
import Home from './screens/Home';
import Contact from './screens/Contact';
import Bibliotheque from './screens/Bibliotheque';
import About from './components/sections/About';
import Navbar from './components/UI/Nav/Navbar';
import NavTop from './components/UI/NavTop/TopBar';
import Footer from './components/UI/Footer/Footer';
import CablesDetails from './components/sections/Service/ServiceDetails/CablesDetails';
import AppareillagesDetails from './components/sections/Service/ServiceDetails/AppareillagesDetails';
import EclairageDetails from './components/sections/Service/ServiceDetails/EclairageDetails';
import ClimatisationDetails from './components/sections/Service/ServiceDetails/ClimatisationDetails';
import VideoDetails from './components/sections/Service/ServiceDetails/VideoDetails';
import AlarmeDetails from './components/sections/Service/ServiceDetails/AlarmeDetails';
import NotFound from './screens/NotFound';
import Emploi from './screens/Emploi';
import DownloadPage from './screens/DownloadPage';

function App() {
  return (
    <div className='App'>
      <NavTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home/>}  />
        <Route path="/Contact" element={<Contact/>}  />
        <Route path="/Emploi" element={<Emploi/>}  />
        <Route path="/DownloadPage" element={<DownloadPage/>}  />
        <Route path="/Bibliotheque" element={<Bibliotheque/>}  />
        <Route path="/About" element={<About/>}  />
        <Route path="/CablesDetails" element={<CablesDetails/>} />
        <Route path="/AppareillagesDetails" element={<AppareillagesDetails/>} />
        <Route path="/EclairageDetails" element={<EclairageDetails/>} />
        <Route path="/ClimatisationDetails" element={<ClimatisationDetails/>} />
        <Route path="/VideoDetails" element={<VideoDetails/>} />
        <Route path="/AlarmeDetails" element={<AlarmeDetails/>} />
        <Route path="*" element={<NotFound/>}  />
      </Routes>
      <Footer />
      </div>
  );
}

export default App;
