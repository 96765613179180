import React from 'react';
import { createRoot } from 'react-dom/client';
 // Mettez à jour l'importation

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/_styles.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);