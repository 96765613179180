import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { motion } from 'framer-motion';
import '../../styles/components/_partenaires.scss';
import CataloguesData from "../../data/PartenairesData";

const Partenaires = () => {
  const serviceBoxVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <main
      id="catalogues"
      initial={{ opacity: 0, y: "-100px" }}
      animate={{ opacity: 10, y: 0 }}
      transition={{ duration: 2.5 }}
      style={{ backgroundColor: `#FFF` }}
    >
      <div className="container pt-2 pb-5">
      <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Nos </span>fournisseurs
          </h3>
          <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={200} duration={2}>
          <h6 className='section-subtitle mx-auto ml-auto'>
        
          </h6>
          </ScrollAnimation>
          <div className="section-content pt-5">
            <div className="partenaire-grid"> {/* Add the "blog-grid" class here */}
              {CataloguesData.map((catalogue) => (
                <ScrollAnimation key={catalogue.id} animateIn='fadeInUp' animateOnce={true} delay={300} duration={7}>
                  <motion.div
                    variants={serviceBoxVariants}
                    initial='hidden'
                    animate='visible'
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className='catalogue-item' // Add the "catalogue-item" class here
                  >
                    <a href={catalogue.url} target="_blank" rel="noopener noreferrer">
                      <img
                        src={catalogue.image}
                        className="card-img-top rounded shadow"
                        alt={catalogue.alt}
                      />
                    </a>
                  </motion.div>
                </ScrollAnimation>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Partenaires;
