import React, { useState, useEffect } from 'react';

const ClimatisationDetails = () => {
    const [images, setImages] = useState([// Ajoutez autant d'images que nécessaire avec leurs descriptions et directions respectives

  
    {
      id: 1,
      url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQsK-vU_rLGyzSrqUdqy6ljvOjl3RtHq3G9tO-jK1XSuVju9qql3p6LCdyT69GK1ggapo&usqp=CAU',
      title:"Climatiseurs Split : Le Confort Polyvalent pour Votre Intérieur !",
      description: "Découvrez l'ultime confort avec notre gamme de climatisation de type split. Conçus pour vous offrir une fraîcheur sans pareille en été et un chauffage optimal en hiver, nos climatiseurs split combinent performances et discrétion pour s'intégrer parfaitement à votre intérieur. Grâce à une technologie avancée, nos climatiseurs garantissent une régulation précise de la température, vous permettant de créer un environnement idéal en toutes saisons. La fonction de déshumidification assure un air plus sain en éliminant l'excès d'humidité, tandis que le mode silencieux vous offre des nuits paisibles. Avec un design élégant et épuré, nos climatiseurs split se fondent harmonieusement dans votre décor, tout en étant faciles à installer et à entretenir. Profitez d'une efficacité énergétique remarquable, réduisant ainsi votre consommation d'énergie et contribuant à un mode de vie plus respectueux de l'environnement. Faites de votre maison un havre de bien-être avec nos climatiseurs de type split, vous permettant de bénéficier d'un confort optimal et d'une atmosphère agréable, quelles que soient les conditions extérieures.",
      direction: 'right',
      isVisible: false,
    },
    {
      id: 2,
      url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCu-VVz_1PzxYqo92piV3lNAQq8NLRfnuu53Kf5zAMuxYUEFMn2R14OpLt7lU-mpNKW4Q&usqp=CAU',
      title:"Climatiseurs Cassette : L'Élégance du Confort Homogène pour Vos Grands Espaces !",
      description: "Découvrez l'expérience de confort ultime avec notre gamme de climatiseurs de type cassette. Conçus pour offrir une climatisation homogène dans les grands espaces, nos climatiseurs cassette allient performances supérieures et discrétion pour un environnement agréable. Grâce à leur configuration encastrée au plafond, ces climatiseurs offrent une répartition uniforme de l'air dans la pièce, éliminant ainsi les zones de températures inégales. Vous bénéficiez ainsi d'un climat intérieur idéal, que ce soit dans votre maison, votre bureau ou tout autre espace professionnel. Nos climatiseurs de type cassette sont équipés de technologies avancées de contrôle de la température et de la ventilation, vous permettant de personnaliser facilement l'atmosphère selon vos préférences. La fonction de programmation vous offre la flexibilité de planifier la climatisation en fonction de vos horaires, tout en optimisant l'efficacité énergétique. Avec un design élégant et discret, nos climatiseurs cassette s'intègrent harmonieusement à votre plafond, préservant ainsi l'esthétique de votre espace intérieur. Profitez d'une climatisation puissante et économe en énergie, réduisant ainsi vos coûts d'exploitation tout en préservant l'environnement. Faites le choix du confort absolu avec nos climatiseurs de type cassette, pour une ambiance agréable et tempérée dans tous vos grands espaces, tout au long de l'année.",
      direction: 'left',
      isVisible: false,
    },

    
  ]);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleImageClick = (id) => {
    setSelectedImageId(id);
    setModalOpen(true);
  };
  const closeModal = () => {
    setSelectedImageId(null);
    setModalOpen(false);
  };

  // Utiliser useEffect pour rendre les images visibles une par une dès que le composant est monté
  useEffect(() => {
    let currentImageIndex = 0;
    const intervalId = setInterval(() => {
      setImages((prevImages) => {
        const updatedImages = prevImages.map((image, index) => ({
          ...image,
          isVisible: index === currentImageIndex ? true : image.isVisible,
        }));
        currentImageIndex++;
        if (currentImageIndex >= images.length) {
          clearInterval(intervalId);
        }
        return updatedImages;
      });
    }, 1000); // Changez la valeur (en millisecondes) pour ajuster le délai d'apparition des images
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Diviser les images en deux parties
  const middleIndex = Math.ceil(images.length / 2);
  const leftImages = images.slice(0, middleIndex);
  const rightImages = images.slice(middleIndex);

  return (
    <main
    id="climatisation"
      initial={{ opacity: 0, y: "-100px" }}
      animate={{ opacity: 10, y: 0 }}
      transition={{ duration: 2.5 }}
      style={{ backgroundColor: `#E9ECEF` }}
    >
    <div className="container bg-body-secondary ">
      {/* Section d'en-tête */}
      <div className="section-header pt-5 pb-5 text-center">
        <h3 className="section-title">
          <span>Nos </span>Climatisations
        </h3>
        <h6 className="section-subtitle mx-auto">
          N'hésitez pas à nous contacter pour toute demande, commentaire ou question que vous pourriez avoir.
        </h6>
      </div>

      {/* Contenu principal */}
      <div className="section-content p-5">
        <div className="row">
          <div className="col-lg-6">
            {leftImages.map((image) => (
              <div
                key={image.id}
                style={{
                  textAlign: image.direction,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onMouseEnter={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: true }
                        : prevImage
                    )
                  )
                }
                onMouseLeave={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: false }
                        : prevImage
                    )
                  )
                }
              >
                <img
                  src={image.url}
                  alt={`Image ${image.id}`}
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    width: image.isVisible ? '250px' : '200px', // Augmenter la taille de l'image au survol
                    height: image.isVisible ? '250px' : '200px',
                    borderRadius: '50%', // Rendre l'image ronde
                    padding: '2%',
                    opacity: 1,
                    transition: 'width 0.5s, height 0.5s', // Ajouter une transition fluide pour l'effet d'agrandissement
                    cursor: 'pointer',
                  }}
                />
                {image.isVisible && <p>{image.title}</p>}
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            {rightImages.map((image) => (
              <div
                key={image.id}
                style={{
                  textAlign: image.direction,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onMouseEnter={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: true }
                        : prevImage
                    )
                  )
                }
                onMouseLeave={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: false }
                        : prevImage
                    )
                  )
                }
              >
                <img
                  src={image.url}
                  alt={`Image ${image.id}`}
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    width: image.isVisible ? '250px' : '200px', // Augmenter la taille de l'image au survol
                    height: image.isVisible ? '250px' : '200px',
                    borderRadius: '50%', // Rendre l'image ronde
                    padding: '2%',
                    opacity: 1,
                    transition: 'width 0.5s, height 0.5s', // Ajouter une transition fluide pour l'effet d'agrandissement
                    cursor: 'pointer',
                  }}
                />
                {image.isVisible && <p>{image.title}</p>}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Fenêtre modale */}
      {isModalOpen && selectedImageId !== null && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={images.find((image) => image.id === selectedImageId)?.url}
              alt={`Image ${selectedImageId}`}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
              }}
            />
            <p>{images.find((image) => image.id === selectedImageId)?.description}</p>
            <button className="close-button" onClick={closeModal}>
              &#x2715;
            </button>
          </div>
        </div>
      )}
    </div>
    </main>
  );
};

export default ClimatisationDetails;
