import React, { useState } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { motion } from 'framer-motion';
import '../styles/components/_blog.scss';
import CataloguesData from "../data/CataloguesData";
// import download from "../assets/img/download.png";
// import { faDownload } from "@fortawesome/free-solid-svg-icons";

const Blog = () => {
  const serviceBoxVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  const [selectedCatalogueLink, setSelectedCatalogueLink] = useState(null);

  const handleDownloadClick = (link) => {
    setSelectedCatalogueLink(link);
  };

  return (
    <main
      id="catalogues"
      initial={{ opacity: 0, y: "-100px" }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 2.5 }}
      style={{ backgroundColor: `#D5F2EE` }}
    >
      <div className="container pt-2 pb-5">
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Nos </span>Catalogues
          </h3>
          <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={200} duration={2}>
            <h6 className='section-subtitle mx-auto ml-auto'>
              Notre bibliothèque regroupe une collection de catalogues de différents fournisseurs. 
              Elle vous offre la possibilité de parcourir et de rechercher des produits ou des fournitures. 
              Elle facilite l'accès à des informations détaillées sur les produits et permet de prendre des décisions lors des processus d'achat auprès de MONDISTRI. 
              Grâce à cette bibliothèque, vous pouvez gagner du temps et optimiser vos recherches en ayant accès à une large gamme de catalogues au même endroit.
            </h6>
          </ScrollAnimation>
          <div className="section-content pt-5">
            <div className="blog-grid">
              {CataloguesData.map((catalogue) => (
                <ScrollAnimation key={catalogue.id} animateIn='fadeInUp' animateOnce={true} delay={300} duration={7}>
                  <motion.div
                    variants={serviceBoxVariants}
                    initial='hidden'
                    animate='visible'
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className='catalogue-item'
                  >
                    <a href={catalogue.url} target="_blank" rel="noopener noreferrer">
                      <img
                        src={catalogue.image}
                        className="card-img-top rounded shadow"
                        alt={catalogue.alt}
                      />
                    </a>
                    {/* <a href={catalogue.url} target="_blank" rel="noopener noreferrer">
                      
                      <img
                        className="img h-25 w-25"
                        src={download} // Remplacez le chemin par le chemin de votre image de téléchargement
                        alt="Télécharger"
                      />
                    </a> */}
                  </motion.div>
                </ScrollAnimation>
              ))}
            </div>
          </div>
        </div>
      </div>
      {selectedCatalogueLink && (
        <div>
          <p>Vous pouvez télécharger le catalogue <a href={selectedCatalogueLink} target="_blank" rel="noopener noreferrer">ici</a></p>
        </div>
      )}
    </main>
  );
};

export default Blog;
