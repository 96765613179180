import React, { Component } from 'react';
import { CCarousel, CCarouselItem, CImage } from '@coreui/react';

import Img1 from '../../assets/img/EPR_Bannière_Octobre_2024_GIF.gif';
// import Img2 from '../../assets/img/Carousel/tempimage02.jpeg';
// import Img3 from '../../assets/img/Carousel/tempimage03.jpeg';
// import Img4 from '../../assets/img/Promotions/promo_spit_caroteuse.png';
// import Img5 from '../../assets/img/Promotions/Promo_Clim.png';
// import Img6 from '../../assets/img/Promotions/promo_legrand_onduleur.png';
// import Img7 from '../../assets/img/Promotions/promo_comunello.png';
// import Img8 from '../../assets/img/Promotions/arrivage_beg.png';
// import Img10 from '../../assets/img/Promotions/promotions_DeltaDor.png';
// import Img9 from '../../assets/img/Promotions/destockage_schneider.png';

class Carousel extends Component {
  constructor(props) {
    super(props);

    this.AUTOCHANGE_TIME = 4000; // 4 seconds per slide
    this.carouselRef = React.createRef(); // Create a reference for the Carousel

    this.state = {
      activeSlide: 0,
    };
  }

  componentDidMount() {
    this.startAutoChange();
  }

  componentWillUnmount() {
    this.stopAutoChange();
  }

  startAutoChange() {
    this.carouselInterval = setInterval(() => {
      this.nextSlide();
    }, this.AUTOCHANGE_TIME);
  }

  stopAutoChange() {
    clearInterval(this.carouselInterval);
  }

  nextSlide() {
    if (this.carouselRef.current && typeof this.carouselRef.current.next === 'function') {
      this.carouselRef.current.next(); // Use the next method from the reference
      this.updateActiveSlide();
    }
  }

  prevSlide() {
    if (this.carouselRef.current && typeof this.carouselRef.current.prev === 'function') {
      this.carouselRef.current.prev(); // Use the prev method from the reference
      this.updateActiveSlide();
    }
  }

  updateActiveSlide() {
    // Get the total number of slides
    const totalSlides = this.carouselRef.current && this.carouselRef.current._slides.length;

    // Calculate the index of the next slide
    const nextSlideIndex = (this.state.activeSlide + 1) % totalSlides;

    // Update the state with the new active slide index
    this.setState({ activeSlide: nextSlideIndex });
  }

  render() {
    return (
      <section id='Carousel' className='carousel-behind-navbar'>
        <div className='container pb-2'>
          <div className='section-header pt-2 pb-5 text-center'>
            {/* <h3 className='section-title'>
              <span>Nos </span>Informations
            </h3> */}
            <CCarousel
               // Set the active slide using the state
            >
              {/* <CCarouselItem>
                <CImage className='d-block w-100 rounded-4' src={Img1} alt='slide 1' />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className='d-block w-100 rounded-4' src={Img2} alt='slide 2' />
              </CCarouselItem> */}
              {/* <CCarouselItem>
                <CImage className='d-block w-100 rounded-4' src={Img3} alt='slide 3' />
              </CCarouselItem> */}
              <CCarouselItem>
                <CImage className='d-block w-100 rounded-4' src={Img1} alt='Férmeture annuel 2023' />
              </CCarouselItem>
              {/* <CCarouselItem>
                <CImage className='d-block w-100 rounded-4' src={Img5} alt='Promotion Climatiseur Haier' />
              </CCarouselItem> */}
              
              {/* <CCarouselItem>
                <CImage className='d-block w-100 rounded-4' src={Img6} alt='Promotion Legrand Onduleurs' />
              </CCarouselItem> */}
              {/* <CCarouselItem>
                <CImage className='d-block w-100 rounded-4' src={Img7} alt='Moteur Portail Coulissant Comunello' />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className='d-block w-100 rounded-4' src={Img8} alt='Arrivage B.E.G' />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className='d-block w-100 rounded-4' src={Img4} alt='Promotion Carotteuse Spit Paslod' />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className='d-block w-100 rounded-4' src={Img10} alt='Promotion Carotteuse Delta Dore' />
              </CCarouselItem> */}
              {/* <CCarouselItem>
                <CImage className='d-block w-100 rounded-4' src={Img9} alt='Destockage Schneider' />
              </CCarouselItem> */}
            </CCarousel>
          </div>
        </div>
      </section>
    );
  }
}

export default Carousel;
