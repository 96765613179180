import React, { Fragment } from 'react';

// import { TypeAnimation } from 'react-type-animation';
// import {motion} from 'framer-motion';
// import {fadeIn} from '../variants';

import Service from "../components/sections/Service/Service";
import Carousel from "../components/sections/Carousel";
// import Facts from "../components/sections/Facts";

// import bgImage from '../assets/img/EPR_Bannière_Octobre_2024_GIF.gif';
import Partenaires from '../components/sections/Partenaires';

const home = () => {
  return (
    <Fragment >
      {/* <div className='home'>
        <motion.div initial={{ opacity: 0, y: '-100px' }}
            animate={{ opacity: 10, y: 0 }}
            transition={{ duration: 2.5 }}
          className='home-content d-block'
          style={{ backgroundImage: `url(${bgImage})` }}
        > */}
          {/* <div className='intro container text-center text-light'>
            <motion.div initial={{ opacity: 0, y: '-100px' }}
            animate={{ opacity: 10, y: 0 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }} 
            transition={{duration: 2.5, repeat: Infinity, repeatType: 'reverse', rotate: 180}}  className='title h1'>Electric
            <span className='title2'>Plus</span></motion.div>
            <motion.div variants={fadeIn('up', 0.3)} whileInView={'show'} viewport={{once: false, amount: 0.7}}
            transition={{ duration: 5.5 }}
            className='sub-title md-4'>
            <TypeAnimation sequence={[
                'Entre nous, le courant passe !',
                2000,
                'Appareillages',
                2000,
                'Câbles',
                2000,
                'VDI',
                2000,
                'Domotique',
                2000,
                'CCTV',
                2000,
                'Alarme',
                2000,
                'Ensemble, innovons le monde de l électricité !',
                2000,
              ]}
              speed={50}
              wrapper='span'
              repeat={Infinity}
              />
            </motion.div>
          </div> */}
        {/* </motion.div>
        </div> */}
          <Carousel />
          <Service />
          <Partenaires />
          {/* <Facts /> */}
    </Fragment>
  );
};

export default home;
