import React, { useState, useEffect } from 'react';

const EclairageDetails = () => {
    const [images, setImages] = useState([// Ajoutez autant d'images que nécessaire avec leurs descriptions et directions respectives

  
    {
      id: 1,
      url: 'https://www.keria.com/media/wysiwyg/CMS/Keria/25/K_170801_types-eclairages_GUIDE.jpg',
      title:"Découvrez notre collection éblouissante d'éclairages intérieurs !",
      description: "Plongez dans un océan de luminosité avec notre gamme d'éclairages intérieurs qui rehausseront la beauté de votre chez-vous. Des designs innovants, des ambiances chaleureuses et des économies d'énergie garanties. Transformez votre intérieur en un havre de lumière !",
      direction: 'right',
      isVisible: false,
    },
    {
      id: 2,
      url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4zL4-6qgNJDYvXQVfw0s8jo_1viRn7bytsw&usqp=CAU',
      title:"Découvrez Notre Collection d'Éclairage Extérieur Éblouissantes !",
      description: "Éclairez votre jardin avec émerveillement : Explorez notre sélection enchantée d'éclairages extérieurs ! Transformez vos soirées en moments magiques grâce à nos designs uniques, qui illumineront votre jardin d'une lueur féerique. Alliez esthétique et praticité avec nos options écoénergétiques pour créer un espace extérieur éblouissant et écologique !",
      direction: 'left',
      isVisible: false,
    },
    {
      id: 3,
      url: 'https://www.objetconnecte.com/wp-content/uploads/2016/04/philips-eclairage-public.jpg',
      title:"Explorez notre Vaste Sélection d'Éclairage Public de Pointe, Garantissant Visibilité, Sécurité et Économies Énergétiques Pour une Communauté Resplendissante !",
      description: "Éclairez vos rues avec excellence : Découvrez notre gamme d'éclairages publics performants ! Offrez à votre ville une illumination optimale avec nos solutions d'éclairage public modernes et durables. Des conceptions innovantes garantissant une visibilité accrue et une consommation énergétique réduite. Faites briller votre communauté en toute sécurité, grâce à notre expertise en éclairage public de pointe.",
      direction: 'right',
      isVisible: false,
    },
    {
      id: 4,
      url: 'https://www.lampesdirect.fr/media/wysiwyg/Blog/Plan-d-eclairage-entrepot-2.png',
      title:" Découvrez notre Gamme d'Éclairage Industriel Haute Performance pour une Productivité Lumineuse et une Efficacité Énergétique sans égales !",
      description: "Illuminez votre industrie avec puissance : Explorez notre sélection d'éclairages industriels haute performance ! Conçus pour répondre aux exigences les plus élevées, nos solutions d'éclairage industriel offrent une luminosité optimale, une fiabilité exceptionnelle et une efficacité énergétique inégalée. Transformez vos espaces de travail en environnements productifs et sécurisés, tout en réalisant des économies sur votre consommation d'énergie. Donnez à votre industrie la lumière dont elle a besoin pour briller !",
      direction: 'left',
      isVisible: false,
    },
    
  ]);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleImageClick = (id) => {
    setSelectedImageId(id);
    setModalOpen(true);
  };
  const closeModal = () => {
    setSelectedImageId(null);
    setModalOpen(false);
  };

  // Utiliser useEffect pour rendre les images visibles une par une dès que le composant est monté
  useEffect(() => {
    let currentImageIndex = 0;
    const intervalId = setInterval(() => {
      setImages((prevImages) => {
        const updatedImages = prevImages.map((image, index) => ({
          ...image,
          isVisible: index === currentImageIndex ? true : image.isVisible,
        }));
        currentImageIndex++;
        if (currentImageIndex >= images.length) {
          clearInterval(intervalId);
        }
        return updatedImages;
      });
    }, 1000); // Changez la valeur (en millisecondes) pour ajuster le délai d'apparition des images
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Diviser les images en deux parties
  const middleIndex = Math.ceil(images.length / 2);
  const leftImages = images.slice(0, middleIndex);
  const rightImages = images.slice(middleIndex);

  return (
    <main
    id="eclairages"
      initial={{ opacity: 0, y: "-100px" }}
      animate={{ opacity: 10, y: 0 }}
      transition={{ duration: 2.5 }}
      style={{ backgroundColor: `#E9ECEF` }}
    >
    <div className="container bg-body-secondary ">
      {/* Section d'en-tête */}
      <div className="section-header pt-5 pb-5 text-center">
        <h3 className="section-title">
          <span>Nos </span>Éclairages
        </h3>
        <h6 className="section-subtitle mx-auto">
          N'hésitez pas à nous contacter pour toute demande, commentaire ou question que vous pourriez avoir.
        </h6>
      </div>

      {/* Contenu principal */}
      <div className="section-content p-5">
        <div className="row">
          <div className="col-lg-6">
            {leftImages.map((image) => (
              <div
                key={image.id}
                style={{
                  textAlign: image.direction,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onMouseEnter={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: true }
                        : prevImage
                    )
                  )
                }
                onMouseLeave={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: false }
                        : prevImage
                    )
                  )
                }
              >
                <img
                  src={image.url}
                  alt={`Image ${image.id}`}
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    width: image.isVisible ? '250px' : '200px', // Augmenter la taille de l'image au survol
                    height: image.isVisible ? '250px' : '200px',
                    borderRadius: '50%', // Rendre l'image ronde
                    padding: '2%',
                    opacity: 1,
                    transition: 'width 0.5s, height 0.5s', // Ajouter une transition fluide pour l'effet d'agrandissement
                    cursor: 'pointer',
                  }}
                />
                {image.isVisible && <p>{image.title}</p>}
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            {rightImages.map((image) => (
              <div
                key={image.id}
                style={{
                  textAlign: image.direction,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onMouseEnter={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: true }
                        : prevImage
                    )
                  )
                }
                onMouseLeave={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: false }
                        : prevImage
                    )
                  )
                }
              >
                <img
                  src={image.url}
                  alt={`Image ${image.id}`}
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    width: image.isVisible ? '250px' : '200px', // Augmenter la taille de l'image au survol
                    height: image.isVisible ? '250px' : '200px',
                    borderRadius: '50%', // Rendre l'image ronde
                    padding: '2%',
                    opacity: 1,
                    transition: 'width 0.5s, height 0.5s', // Ajouter une transition fluide pour l'effet d'agrandissement
                    cursor: 'pointer',
                  }}
                />
                {image.isVisible && <p>{image.title}</p>}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Fenêtre modale */}
      {isModalOpen && selectedImageId !== null && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={images.find((image) => image.id === selectedImageId)?.url}
              alt={`Image ${selectedImageId}`}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
              }}
            />
            <p>{images.find((image) => image.id === selectedImageId)?.description}</p>
            <button className="close-button" onClick={closeModal}>
              &#x2715;
            </button>
          </div>
        </div>
      )}
    </div>
    </main>
  );
};

export default EclairageDetails;
