import React, { useState, useEffect } from 'react';

const VideoDetails = () => {
    const [images, setImages] = useState([// Ajoutez autant d'images que nécessaire avec leurs descriptions et directions respectives

  
    {
      id: 1,
      url: 'https://www.securitegooddeal.com/images/helix/ban_video_enregistreur.jpg',
      title:"Gestion Complète et Intuitive : Enregistreurs Analogiques et IP de Dahua pour une Surveillance Performante !",
      description: "Dahua offre une gamme complète d'enregistreurs IP et analogiques, garantissant une surveillance fiable et efficace. Les enregistreurs IP captent des vidéos de haute qualité avec des fonctionnalités avancées telles que la détection de mouvement intelligente et une visualisation en temps réel à distance. Les enregistreurs analogiques offrent également une qualité vidéo supérieure avec une gestion intuitive. Avec une interface conviviale et des fonctionnalités de sauvegarde automatique, la configuration et la gestion de vos enregistreurs sont simples et pratiques. Faites confiance à Dahua pour une sécurité de pointe, protégeant ce qui compte le plus pour vous.",
      direction: 'left',
      isVisible: false,
    },
    {
      id: 2,
      url: 'https://i.ytimg.com/vi/LDd0dk5riUM/maxresdefault.jpg',
      title:"Surveillance Polyvalente et Discrète : Caméras de Type Dôme de Dahua !",
      description: "Dahua propose une gamme complète de caméras de surveillance de type dôme, offrant une solution de sécurité fiable et polyvalente. Ces caméras, grâce à leur conception discrète et leur forme compacte, sont idéales pour une surveillance discrète dans divers environnements intérieurs ou extérieurs. Les caméras de type dôme de Dahua sont dotées de fonctionnalités avancées telles que la vision nocturne améliorée, la détection de mouvement intelligente et la résistance aux intempéries, ce qui les rend parfaites pour une utilisation en toutes conditions. Profitez d'une qualité vidéo supérieure, d'une installation facile et d'une gestion intuitive, tout en assurant une surveillance efficace et complète de vos espaces les plus importants. Avec les caméras de type dôme de Dahua, soyez assuré de la tranquillité d'esprit et de la sécurité optimale de votre propriété.",
      direction: 'left',
      isVisible: false,
    },
    {
      id: 3,
      url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGIsSSoxJE-6eK0MTNv-pzp4OWMKrjFfCR_4ngUMMRhH73zApbLkiyHqwybLoFS5auOWY&usqp=CAU',
      title:"Surveillance Étendue et Haute Performance : Caméras Bullet Longue Portée de Dahua !",
      description: "Les caméras de type bullet longue portée de Dahua offrent une surveillance étendue et de haute qualité. Conçues pour résister aux intempéries et garantir des performances fiables en extérieur, ces caméras vous permettent de surveiller efficacement de grands espaces tels que les parkings, les entrepôts et les grands périmètres. Grâce à leur vision nocturne avancée, elles assurent une surveillance continue même dans l'obscurité totale, capturant les moindres détails avec précision. Avec des fonctionnalités avancées telles que la détection de mouvement et une gestion intuitive, les caméras de type bullet longue portée de Dahua vous offrent une sécurité optimale et une tranquillité d'esprit totale pour une surveillance étendue de vos zones les plus importantes.",
      direction: 'left',
      isVisible: false,
    },
    {
      id: 4,
      url: 'https://www.teleco.com/wp-content/uploads/2020/08/perfectview-video-surveillance-systems.jpg',
      title:"Surveillance Évoluée et Précise : Caméras Dôme Motorisé et Thermique de Dahua !",
      description: "Les caméras de type dôme motorisé et thermique de Dahua offrent une surveillance évoluée et complète. Grâce à leur conception motorisée permettant une rotation horizontale et verticale, elles peuvent couvrir une large zone de surveillance, tandis que la technologie thermique assure une détection précise des mouvements en détectant la chaleur corporelle, même dans l'obscurité totale. Ces caméras sont idéales pour une utilisation en extérieur grâce à leur résistance aux intempéries, offrant ainsi des performances fiables quelles que soient les conditions météorologiques. Avec une qualité vidéo haute définition, une vision nocturne avancée et une fonctionnalité de zoom puissante, ces caméras garantissent une surveillance efficace et sécurisée de vos zones les plus sensibles. Profitez d'une gestion intuitive grâce à des interfaces conviviales et à des fonctionnalités avancées, telles que la détection intelligente des mouvements et l'enregistrement programmable. Avec les caméras de type dôme motorisé et thermique de Dahua, vous bénéficiez d'une sécurité optimale pour votre propriété et vos proches, même dans les environnements les plus exigeants.",
      direction: 'left',
      isVisible: false,
    },
    
  ]);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleImageClick = (id) => {
    setSelectedImageId(id);
    setModalOpen(true);
  };
  const closeModal = () => {
    setSelectedImageId(null);
    setModalOpen(false);
  };

  // Utiliser useEffect pour rendre les images visibles une par une dès que le composant est monté
  useEffect(() => {
    let currentImageIndex = 0;
    const intervalId = setInterval(() => {
      setImages((prevImages) => {
        const updatedImages = prevImages.map((image, index) => ({
          ...image,
          isVisible: index === currentImageIndex ? true : image.isVisible,
        }));
        currentImageIndex++;
        if (currentImageIndex >= images.length) {
          clearInterval(intervalId);
        }
        return updatedImages;
      });
    }, 1000); // Changez la valeur (en millisecondes) pour ajuster le délai d'apparition des images
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Diviser les images en deux parties
  const middleIndex = Math.ceil(images.length / 2);
  const leftImages = images.slice(0, middleIndex);
  const rightImages = images.slice(middleIndex);

  return (
    <main
    id="video"
      initial={{ opacity: 0, y: "-100px" }}
      animate={{ opacity: 10, y: 0 }}
      transition={{ duration: 2.5 }}
      style={{ backgroundColor: `#E9ECEF` }}
    >
    <div className="container bg-body-secondary ">
      {/* Section d'en-tête */}
      <div className="section-header pt-5 pb-5 text-center">
        <h3 className="section-title">
          <span>Nos </span>Vidéo Surveillance
        </h3>
        <h6 className="section-subtitle mx-auto">
          N'hésitez pas à nous contacter pour toute demande, commentaire ou question que vous pourriez avoir.
        </h6>
      </div>

      {/* Contenu principal */}
      <div className="section-content p-5">
        <div className="row">
          <div className="col-lg-6">
            {leftImages.map((image) => (
              <div
                key={image.id}
                style={{
                  textAlign: image.direction,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onMouseEnter={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: true }
                        : prevImage
                    )
                  )
                }
                onMouseLeave={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: false }
                        : prevImage
                    )
                  )
                }
              >
                <img
                  src={image.url}
                  alt={`Image ${image.id}`}
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    width: image.isVisible ? '250px' : '200px', // Augmenter la taille de l'image au survol
                    height: image.isVisible ? '250px' : '200px',
                    borderRadius: '50%', // Rendre l'image ronde
                    padding: '2%',
                    opacity: 1,
                    transition: 'width 0.5s, height 0.5s', // Ajouter une transition fluide pour l'effet d'agrandissement
                    cursor: 'pointer',
                  }}
                />
                {image.isVisible && <p>{image.title}</p>}
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            {rightImages.map((image) => (
              <div
                key={image.id}
                style={{
                  textAlign: image.direction,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onMouseEnter={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: true }
                        : prevImage
                    )
                  )
                }
                onMouseLeave={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: false }
                        : prevImage
                    )
                  )
                }
              >
                <img
                  src={image.url}
                  alt={`Image ${image.id}`}
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    width: image.isVisible ? '250px' : '200px', // Augmenter la taille de l'image au survol
                    height: image.isVisible ? '250px' : '200px',
                    borderRadius: '50%', // Rendre l'image ronde
                    padding: '2%',
                    opacity: 1,
                    transition: 'width 0.5s, height 0.5s', // Ajouter une transition fluide pour l'effet d'agrandissement
                    cursor: 'pointer',
                  }}
                />
                {image.isVisible && <p>{image.title}</p>}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Fenêtre modale */}
      {isModalOpen && selectedImageId !== null && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={images.find((image) => image.id === selectedImageId)?.url}
              alt={`Image ${selectedImageId}`}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
              }}
            />
            <p>{images.find((image) => image.id === selectedImageId)?.description}</p>
            <button className="close-button" onClick={closeModal}>
              &#x2715;
            </button>
          </div>
        </div>
      )}
    </div>
    </main>
  );
};

export default VideoDetails;
